import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';

const ignoreErrorMessages = [
  'User is not authenticated',
  'jwt expired',
  'Page not found',
  'Object not found',
  'Attribute not found',
  'Chart not found',
  'Sheet not found',
  'Limit exceeded',
];

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors
      .filter(({ message }) => !ignoreErrorMessages.find((ig) => message.includes(ig)))
      .forEach(({ message, path }) => {
        const messageLines = message
          .split('\n')
          .filter(Boolean)
          .filter((line) => line?.charAt(0) !== '/' && line?.charAt(0) !== ' ');
        Sentry.captureMessage(`[GraphQL][${path}]: ${messageLines.join('\n')}`);
      });
  if (networkError) {
    // Sentry.captureMessage(`[Network]: ${networkError}`);
  }
});
