import { ErrorPage } from '@/libs/compass-core-ui';
import * as Sentry from '@sentry/react';
import { ReactNode } from 'react';

interface ErrorBoundaryProps {
  children: ReactNode;
}

export const ErrorBoundary = ({ children }: ErrorBoundaryProps) => {
  // Uncaught exceptions are automatically captured by Sentry's global handlers
  // https://docs.sentry.io/platforms/javascript/guides/react/usage/#capturing-errors

  const Fallback = () => (
    <div
      id='error-boundary'
      style={{
        paddingTop: '70px',
        paddingLeft: '24px',
        paddingRight: '24px',
        height: 'calc(100vh - 70px)',
        backgroundColor: '#2A2A2A',
        color: '#FAF7F2',
        overflow: 'hidden',
      }}>
      <ErrorPage />
    </div>
  );

  return <Sentry.ErrorBoundary fallback={Fallback}>{children}</Sentry.ErrorBoundary>;
};
