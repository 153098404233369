import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { METRICS_ENDPOINT } from './constants';
import './index.css';

let sentryInitialized = false;

localStorage.setItem('chakra-ui-color-mode', 'dark');

const initSentry = () => {
  if (!sentryInitialized) {
    // Error and performance metrics
    sentryInitialized = true;
    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      tunnel: METRICS_ENDPOINT,
      integrations: [new Sentry.BrowserTracing()],
      environment: import.meta.env.VITE_ENV,
      release: import.meta.env.VITE_RELEASE_NAME,
      // Performance Monitoring
      tracesSampleRate: 0.5, // Capture % of transactions
    });
  }
};

if (
  import.meta.env.VITE_ENV === 'prod' ||
  import.meta.env.VITE_ENV === 'alpha' ||
  import.meta.env.VITE_ENV === 'dev'
) {
  initSentry();
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
