import { Link, useDeviceSize } from '@/libs/compass-core-ui';
import { EnvContext } from '@/libs/compass-web-utils';
import { Popover, PopoverContent, PopoverTrigger, Stack, Text } from '@chakra-ui/react';
import { useContext } from 'react';

interface Props {
  direction: 'row' | 'column';
}

export const HeaderText = ({ direction }: Props) => {
  const { mobile } = useDeviceSize();
  const { environment } = useContext(EnvContext);

  return (
    <>
      <Stack direction={direction} spacing={direction === 'row' ? 2 : 0}>
        {!mobile && <Text sx={{ opacity: 0.8, fontFamily: 'CygnitoMonoPro' }}>Quest Bound</Text>}

        <Popover>
          <PopoverTrigger>
            <Text
              role='button'
              sx={{
                color: 'info',
                textDecoration: 'underline',
              }}>
              Coming to Desktop
            </Text>
          </PopoverTrigger>
          <PopoverContent sx={{ width: '350px' }}>
            <Stack spacing={1} padding={2}>
              <Text>
                Quest Bound's web servers will shut down at the end of 2024. It will launch as a
                desktop app shortly before.
              </Text>

              <Stack direction='column' spacing={1}>
                <Text>
                  To support the desktop app development and receive a discounted copy, follow the
                  upcoming
                </Text>
                <Link
                  href='https://www.kickstarter.com/projects/quest-bound/quest-bound-tabletop-game-engine-desktop-app'
                  target='_blank'
                  sx={{ color: 'common.white', textDecoration: 'underline' }}>
                  Kickstarter campaign.
                </Link>
              </Stack>
            </Stack>
          </PopoverContent>
        </Popover>
      </Stack>
    </>
  );
};
