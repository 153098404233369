import { useCurrentUser } from '@/libs/compass-api';
import { useEffect, useRef } from 'react';

export const useFeaturebase = () => {
  const { currentUser } = useCurrentUser();

  const initialized = useRef<boolean>(false);

  useEffect(() => {
    if (!currentUser || initialized.current) return;

    const script = document.createElement('script');
    script.src = 'https://do.featurebase.app/js/sdk.js';
    script.id = 'featurebase-sdk';
    document.head.appendChild(script);

    const win: any = window;

    if (typeof win.Featurebase !== 'function') {
      win.Featurebase = function () {
        (win.Featurebase.q = win.Featurebase.q || []).push(arguments);
      };
    }
    win.Featurebase(
      'identify',
      {
        organization: 'questbound',
        email: currentUser.email,
        name: currentUser.username,
        id: currentUser.id,
        profilePicture: currentUser.avatarSrc ?? undefined,
      },
      (err: any) => {
        // Callback function. Called when identify completed.
      },
    );

    win.Featurebase('initialize_portal_widget', {
      organization: 'questbound',
      placement: 'left',
      fullScreen: false,
      initialPage: 'MainView', // optional (MainView, RoadmapView, CreatePost, PostsView, ChangelogView)
    });

    initialized.current = true;
  }, [currentUser]);
};
