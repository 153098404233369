import { HelpButton } from '@/components/help-button';
import { JoinDiscord, useCurrentUser, UserRole, useSignOut } from '@/libs/compass-api';
import { Avatar, UserDetails } from '@/libs/compass-core-ui';
import { SettingsContext } from '@/libs/compass-web-utils';
import { useNotifications } from '@/stores';
import {
  Container,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
} from '@chakra-ui/react';
import { ArrowCircleUp, Lightbulb, Logout, Report, Settings } from '@mui/icons-material';
import { useContext, useState } from 'react';

export const UserMenu = () => {
  const { currentUser } = useCurrentUser();
  const { openSettingsModal } = useContext(SettingsContext);
  const { setUpgradeCta } = useNotifications();
  const { signOut } = useSignOut();

  const [reportModalOpen, setReportModalOpen] = useState(false);

  return (
    <>
      <Popover>
        <PopoverTrigger>
          <div role='button'>
            <Avatar alt='Profile' variant='rounded' src={currentUser?.avatarSrc || undefined}>
              {currentUser?.username?.charAt(0)}
            </Avatar>
          </div>
        </PopoverTrigger>
        <PopoverContent>
          <Stack padding={2} spacing={4}>
            <UserDetails
              avatarSrc={currentUser?.avatarSrc}
              email={currentUser?.email}
              username={currentUser?.username}
            />
            {currentUser?.role !== UserRole.PUBLISHER && (
              <Container
                role='button'
                onClick={() => {
                  setUpgradeCta(true);
                }}>
                <Stack width='100%' direction='row' spacing={4} alignItems='center'>
                  <ArrowCircleUp fontSize='small' />
                  <Text>Upgrade Account</Text>
                </Stack>
              </Container>
            )}
            <Container
              role='button'
              onClick={() => {
                openSettingsModal(true);
              }}>
              <Stack width='100%' direction='row' spacing={4} alignItems='center'>
                <Settings fontSize='small' />
                <Text>Settings</Text>
              </Stack>
            </Container>
            <Container role='button'>
              <JoinDiscord
                size='small'
                align='start'
                label={<Text sx={{ ml: '8px' }}>Discord</Text>}
                style={{ paddingLeft: '4px' }}
              />
            </Container>
            <Container role='button'>
              <Stack width='100%' direction='row' spacing={4} alignItems='center'>
                <Lightbulb fontSize='small' />
                <Link
                  href='https://feedback.questbound.com'
                  target='_blank'
                  sx={{ '&:hover': { textDecoration: 'none' } }}>
                  Feedback
                </Link>
              </Stack>
            </Container>
            <Container role='button'>
              <Stack
                width='100%'
                direction='row'
                spacing={4}
                alignItems='center'
                onClick={() => {
                  setReportModalOpen(true);
                }}>
                <Report fontSize='small' />
                <Text>Report</Text>
              </Stack>
            </Container>
            <Container role='button'>
              <HelpButton />
            </Container>
            <Container onClick={signOut} role='button'>
              <Stack width='100%' direction='row' spacing={4} alignItems='center'>
                <Logout fontSize='small' />
                <Text>Sign Out</Text>
              </Stack>
            </Container>
          </Stack>
        </PopoverContent>
      </Popover>

      {reportModalOpen && (
        <Modal isOpen={reportModalOpen} onClose={() => setReportModalOpen(false)} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Report Copyright Violations</ModalHeader>
            <ModalBody>
              <Stack spacing={2}>
                <Text>
                  For violations of the DMCA or other copyright issues, email dmca@questbound.com
                </Text>
                <Text>For all other concerns, email support@questbound.com</Text>
              </Stack>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};
