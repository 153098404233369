import { useCurrentUser, UserRole, useUpgradeUser } from '@/libs/compass-api';
import { Divider } from '@/libs/compass-core-ui';
import { useNotifications } from '@/stores';
import { Button, List, ListIcon, ListItem, Stack, Text } from '@chakra-ui/react';
import { AutoStories, Newspaper, People, Person } from '@mui/icons-material';
import { format } from 'date-fns';

export const MembershipSettings = () => {
  const { currentUser } = useCurrentUser();
  const { setUpgradeCta } = useNotifications();
  const { manageUser, loading } = useUpgradeUser();

  const handleManage = async () => {
    const checkoutUrl = await manageUser();
    if (checkoutUrl) {
      window.location.href = checkoutUrl;
    }
  };

  const expiration =
    currentUser?.membershipExpiration === 'never'
      ? 'Never'
      : format(new Date(Number(currentUser?.membershipExpiration ?? '')), 'MMMM d, yyyy');

  const role = currentUser?.role ?? UserRole.USER;

  return (
    <Stack spacing={4} padding={2}>
      <Text sx={{ opacity: 0.7 }}>Membership</Text>
      <Divider />

      <Stack direction='row' spacing={2} flexWrap='wrap'>
        <Button
          onClick={() => {
            setUpgradeCta(true);
          }}>
          Upgrade Membership
        </Button>
        {role !== UserRole.USER && (
          <Button onClick={handleManage} isLoading={loading}>
            Manage Membership
          </Button>
        )}
      </Stack>

      {role === UserRole.USER ? (
        <Text fontSize='1.5rem'>Free</Text>
      ) : role === UserRole.CREATOR ? (
        <Text fontSize='1.5rem'>Creator</Text>
      ) : (
        <Text fontSize='1.5rem'>Publisher</Text>
      )}

      {role !== UserRole.USER && <Text fontSize='1.3rem'>{`Expires: ${expiration}`}</Text>}

      {role === UserRole.USER ? (
        <List spacing={3} pl={1}>
          <ListItem>
            <ListIcon as={AutoStories} />1 custom ruleset
          </ListItem>

          <ListItem>
            <ListIcon as={Person} />1 character per ruleset
          </ListItem>
          <ListItem>
            <ListIcon as={People} />
            Add up to 5 players per ruleset
          </ListItem>
        </List>
      ) : role === UserRole.CREATOR ? (
        <List spacing={3} pl={1}>
          <ListItem>
            <ListIcon as={AutoStories} />
            Unlimited custom rulesets
          </ListItem>

          <ListItem>
            <ListIcon as={Person} />
            Unlimited characters for every ruleset
          </ListItem>
          <ListItem>
            <ListIcon as={People} />
            Add up to 15 players per ruleset
          </ListItem>
          <ListItem>
            <ListIcon as={Newspaper} />
            Publish and sell one ruleset
          </ListItem>
        </List>
      ) : (
        <List spacing={3} pl={1}>
          <ListItem>
            <ListIcon as={AutoStories} />
            Unlimited custom rulesets
          </ListItem>

          <ListItem>
            <ListIcon as={Person} />
            Unlimited characters for every ruleset
          </ListItem>
          <ListItem>
            <ListIcon as={People} />
            Unlimited players per custom ruleset
          </ListItem>
          <ListItem>
            <ListIcon as={Newspaper} />
            Unlimited published content
          </ListItem>
        </List>
      )}
    </Stack>
  );
};
