import { useCurrentUser, useUpgradeUser } from '@/libs/compass-api';
import { useNotifications } from '@/stores';
import {
  Button,
  CircularProgress,
  Link,
  List,
  ListIcon,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { AutoStories, BugReport, Newspaper, People, Person, Verified } from '@mui/icons-material';

export const CreatorCTA = () => {
  const { isCreator } = useCurrentUser();
  const { upgradeCta, setUpgradeCta } = useNotifications();
  const { upgradeUser, loading } = useUpgradeUser();

  const handleUpgrade = async (isAnnual: boolean) => {
    const checkoutUrl = await upgradeUser(isAnnual);
    if (checkoutUrl) {
      window.location.href = checkoutUrl;
    }
  };

  return (
    <Modal isOpen={!!upgradeCta} onClose={() => setUpgradeCta(false)} isCentered size='xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader textAlign='center' sx={{ fontSize: '2.5rem' }}>
          {isCreator ? 'Become a Publisher on Quest Bound' : 'Become a Creator on Quest Bound'}
        </ModalHeader>
        <ModalBody sx={{ padding: 4 }}>
          <Stack spacing={6} align='center'>
            {isCreator ? <PublisherList /> : <CreatorList />}

            {isCreator ? (
              <Stack width='100%' justify='center' align='center'>
                <Text fontSize='1.3rem'>Contact Us</Text>
                <Text fontSize='1.3rem'>publishing@questbound.com</Text>
              </Stack>
            ) : (
              <Stack direction='row' spacing={12}>
                <Stack height={150} justifyContent='space-between'>
                  <Text textAlign='center' as='h2' fontSize='2rem'>
                    $9 / Month
                  </Text>

                  <Button size='lg' onClick={() => handleUpgrade(false)} isDisabled={loading}>
                    Subscribe Monthly
                  </Button>
                </Stack>

                <Stack height={150} justifyContent='space-between'>
                  <Stack>
                    <Text textAlign='center' as='h2' fontSize='2rem'>
                      $90 / Year
                    </Text>
                    <Text textAlign='center' fontStyle='italic' fontSize='0.9rem'>
                      $7.50 / Month
                    </Text>
                  </Stack>

                  <Button size='lg' onClick={() => handleUpgrade(true)} isDisabled={loading}>
                    Subscribe Annually
                  </Button>
                </Stack>
              </Stack>
            )}

            {loading && <CircularProgress isIndeterminate />}

            <Link href='https://signup.questbound.com#pricing' isExternal>
              Learn more
            </Link>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const CreatorList = () => (
  <>
    <List spacing={3}>
      <ListItem fontSize='1.2rem'>
        <ListIcon fontSize='1.2rem' as={AutoStories} />
        Unlimited custom rulesets
      </ListItem>

      <ListItem fontSize='1.2rem'>
        <ListIcon as={Person} fontSize='1.2rem' />
        Unlimited characters for every ruleset
      </ListItem>
      <ListItem fontSize='1.2rem'>
        <ListIcon as={People} fontSize='1.2rem' />
        Add up to 15 players per ruleset
      </ListItem>
      <ListItem fontSize='1.2rem'>
        <ListIcon as={Newspaper} fontSize='1.2rem' />
        Publish and sell one ruleset
      </ListItem>
    </List>
  </>
);

const PublisherList = () => (
  <>
    <List spacing={3}>
      <ListItem fontSize='1.2rem'>
        <ListIcon fontSize='1.2rem' as={Newspaper} />
        Unlimited published content
      </ListItem>
      <ListItem fontSize='1.2rem'>
        <ListIcon as={People} fontSize='1.2rem' />
        Collaborate with other creators in real time
      </ListItem>
      <ListItem fontSize='1.2rem'>
        <ListIcon as={BugReport} fontSize='1.2rem' />
        Priority support and feature requests
      </ListItem>
      <ListItem fontSize='1.2rem'>
        <ListIcon as={Verified} fontSize='1.2rem' />
        Add a verified badge to your published content
      </ListItem>
    </List>
  </>
);
