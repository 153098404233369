import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Report as ReportIcon } from '@mui/icons-material';
import { useState } from 'react';

export const Report = () => {
  const [reportModalOpen, setReportModalOpen] = useState(false);

  return (
    <>
      <Stack
        role='button'
        direction='row'
        spacing={2}
        onClick={() => {
          setReportModalOpen(true);
        }}>
        <ReportIcon fontSize='small' />
        <Text>Report</Text>
      </Stack>

      {reportModalOpen && (
        <Modal isOpen={reportModalOpen} onClose={() => setReportModalOpen(false)} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Report Copyright Violations</ModalHeader>
            <ModalBody>
              <Stack spacing={2}>
                <Text>
                  For violations of the DMCA or other copyright issues, email dmca@questbound.com
                </Text>
                <Text>For all other concerns, email support@questbound.com</Text>
              </Stack>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};
